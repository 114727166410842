import React from "react"
import { Routes } from "../../data/routes"
import { FamilyFAQs } from "../../data/faq"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"

const Content = {
  pageName: "FAQs For Families",
  header: "Frequently Asked Questions",
  description: "Frequently Asked Questions",
  faqs: FamilyFAQs,
}

function FAQSection() {
  // Original TailwindUI component: https://tailwindui.com/components/marketing/sections/faq-sections#component-8699d80b13ef524eb573e54b4d4b89d1
  function FAQLink(props: { action: string; link: string }) {
    if (props.link.startsWith("http") || props.link.startsWith("mailto")) {
      return (
        <a className="text-blue" href={props.link} target="_blank">
          {props.action}.
        </a>
      )
    }
    return (
      <Link to={props.link} className="text-blue">
        {props.action}.
      </Link>
    )
  }

  return (
    <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h1 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          {Content.header}
        </h1>
        <dl className="mt-6 space-y-6 divide-y divide-gray-200">
          {Content.faqs.map(faq => (
            <Disclosure as="div" key={faq.question} className="pt-6">
              {({ open }) => (
                <>
                  <dt className="text-lg">
                    <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                      <span className="font-medium text-gray-900">
                        {faq.question}
                      </span>
                      <span className="ml-6 h-7 flex items-center">
                        <ChevronDownIcon
                          className={`h-6 w-6 transform ${
                            open ? "-rotate-180" : "rotate-0"
                          }`}
                          aria-hidden="true"
                        />
                      </span>
                    </Disclosure.Button>
                  </dt>
                  <Disclosure.Panel as="dd" className="mt-2 pr-12">
                    <p className="text-base text-gray-500">
                      {faq.answer}&nbsp;
                      {faq.action && (
                        <FAQLink action={faq.action} link={faq.link} />
                      )}
                    </p>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </dl>
      </div>
    </div>
  )
}

const FAQ = () => {
  return (
    <Layout>
      <Seo
        title={Content.pageName}
        description={Content.description}
        route={Routes.familyFaq}
      />
      <FAQSection />
    </Layout>
  )
}

export default FAQ
